var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "vs-popup",
    {
      staticClass: "popup--ban-user",
      attrs: {
        title: "Silinen İçeriğin Sebebini İçeren Mesaj",
        active: _vm.active
      },
      on: {
        "update:active": function($event) {
          _vm.active = $event
        },
        close: _vm.close
      }
    },
    [
      _c(
        "div",
        {
          staticClass: "center label",
          on: {
            click: _vm.dropDown,
            topic: function($event) {
              return _vm.onChange($event)
            }
          }
        },
        [
          _c("label", [_vm._v("Göndermek istediğiniz mesajı seçiniz.")]),
          _c("v-select", {
            attrs: { options: _vm.durations, placeholder: "Seçiniz..." },
            model: {
              value: _vm.duration,
              callback: function($$v) {
                _vm.duration = $$v
              },
              expression: "duration"
            }
          })
        ],
        1
      ),
      _c("div", [_vm._v(_vm._s(_vm.message))]),
      _c(
        "div",
        { staticClass: "action-buttons text-right mt-6" },
        [
          _c(
            "vs-button",
            { attrs: { type: "flat" }, on: { click: _vm.close } },
            [_vm._v("\n      Vazgeç\n    ")]
          ),
          _c(
            "vs-button",
            {
              staticClass: "ml-4",
              attrs: { disabled: !_vm.duration },
              on: {
                click: function($event) {
                  return _vm.submit($event)
                }
              }
            },
            [_vm._v("\n      Gönder\n    ")]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }