<template>
  <vs-popup title="Silinen İçeriğin Sebebini İçeren Mesaj" :active.sync="active" @close="close" class="popup--ban-user">
    <div @click="dropDown" @topic="onChange($event)" class="center label">
      <label>Göndermek istediğiniz mesajı seçiniz.</label>
      <v-select :options="durations" v-model="duration" placeholder="Seçiniz..."></v-select>
    </div>
    <div>{{ message }}</div>
    <div class="action-buttons text-right mt-6">
      <vs-button type="flat" @click="close">
        Vazgeç
      </vs-button>
      <vs-button class="ml-4" @click="submit($event)" :disabled="!duration">
        Gönder
      </vs-button>
    </div>
  </vs-popup>
</template>
<script>
import vSelect from 'vue-select';
import { mapActions, mapGetters } from 'vuex';
import ComplaintService from '@/services/ComplaintService';
import TopicService from '@/services/TopicService';
import BroadcastService from '@/services/BroadcastService';
import EntryService from '@/services/EntryService';
import EventBus from '@/utils/events.js';
export default {
  name: 'PopupComplaintSendMessage',
  components: {
    'v-select': vSelect
  },
  props: ['isActive', 'user', 'topic', 'id', 'userId', 'tab', 'entry'],
  data() {
    return {
      active: false,
      counterDanger: false,
      cause: '',
      durations: [],
      duration: null,
      message: '',
      userData: {}
    };
  },
  computed: {
    ...mapGetters('complaint', ['list'], 'deleteEntry')
  },
  methods: {
    ...mapActions('complaint', { getList: 'getFilterList' }),
    onChange() {
      this.message = this.target[this.duration.label];
      this.message = this.message.replaceAll('***TOPIC***', "''" + this.topic + "''");
      this.message = this.message.replaceAll('***DIO***', "''" + this.entry + "''");
    },

    async dropDown() {
      const result2 = await ComplaintService.getByMessage(this.tab);
      this.durations = result2.data;
      this.target = result2.target;
    },
    ...mapActions('user', ['banUser']),
    async close() {
      this.$emit('close', false);
    },
    async mounted() {
      const result2 = ComplaintService.getByMessage(this.tab);

      for (let i = 0; i < result2.data.length; i++) {
        const key = Object.keys(this.result2.data[i])[0];
        this.durations.push(key);
      }
    },
    async submit() {
      if (confirm('Silmek istediğinize emin misiniz?')) {
        if (this.tab === 'entry') {
          await EntryService.delete(this.id);
        }
        if (this.tab === 'topic') {
          await TopicService.delete(this.id);
        }
        try {
          let userId = this.userId;
          let tab = this.tab;
          const payload = {
            message: this.message,
            userId,
            tab
          };
          await BroadcastService.sendMessage(payload);
          this.$vs.notify({
            color: 'success',
            text: 'Başarıyla silindi ve mesaj gönderildi.'
          });
          EventBus.$emit('refresh-page');
          this.$emit('close', false);
        } catch (e) {
          this.$vs.notify({
            color: 'danger',
            text: 'İşlem başarısız. Lütfen tekrar deneyin.'
          });
        }
      }
    }
  },
  watch: {
    isActive(val) {
      this.active = val;
    },
    duration($event) {
      this.onChange($event);
    }
  }
};
</script>
<style lang="scss">
.popup--ban-user {
  .vs-popup {
    max-width: 450px;
  }
  .vs-popup--content {
    overflow: visible;
  }
}
</style>
